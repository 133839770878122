.portafolio_tags {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: space-around;
    flex-wrap: wrap
}

.portafolio_tags__tag {
    margin: 1rem;
    background-color: rgba(59, 130, 246, 0.1);
    border-radius: 9999px;
    padding: 0.2rem 1rem;
    color: var(--color-title);
    font-weight: 400;
    font-size: 0.9rem;
    align-self: center;
    transition: transform .5s ease;
}


.portafolio_tags__tag:hover  {
    transform: scale(1.2);
  }

@media  (max-width: 850px) {
    .portafolio_tags__tag {
        margin: 0.8rem;
    }

}
