* {
    box-sizing: border-box;
    padding: 0;
    margin:  0;
    scroll-behavior:  smooth;
    font-family: var(--font-family); 
}

/*body {
    background:  var(--color-bg);
    color: var(--color-text);
} */

/*
.gradient__bg {
    background:-moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
  
    /* safari 5.1+,chrome 10+ 
    background:-webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
  */
    /* opera 11.10+ 
    background:-o-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
    */
    /* ie 10+ 
    background:-ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
  */
     
    /* global 92%+ browsers support 
    background:radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
  } 

  */

  .gradient__text {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
    
  .section__margin {
    margin: 4rem 6rem;
  }

  .section__padding {
    margin: 0rem
  }

  /* text */ 
  .scale-in-center {
	-webkit-animation: scale-in-center 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-center 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-1-16 23:33:3
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  
  .tracking-in-expand {
    -webkit-animation: tracking-in-expand 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
            animation: tracking-in-expand 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
  }
/* ----------------------------------------------
 * Generated by Animista on 2022-1-16 23:57:10
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */
 @-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.portfolio__body {
  margin: 1rem 3rem;
}


/*  Jupyter notebooks */

.block .cell-row .cell-content.source-markdown {
  line-height: 1.5rem;
}

.block-output-content {
  max-height: 600px !important;
  height: 100% !important;
  background: white;
}

.block .cell-row .cell-content.source-markdown {
  background-color: rgba(255, 255, 255, 0.8);
}

.cell-row {
  margin: 0.5rem 0rem;
}

.cell-row img {
  max-width: 100% !important;

}

.block p, h1, li {
  margin: 1rem 0 1rem 0;
}

.katex {
  margin: 1rem 0 1rem 0;
}

@media  (max-width: 850px) { 
  .portfolio__body {
    margin: 1rem 1rem;
  }
}