.portfolio__navbar {
    display:  flex;
    justify-content:  space-between;
    align-items:  center;
    padding:  2rem 3rem;
}

.portfolio__navbar-links {
    flex: 1;
    display:  flex;
    align-items:  center;
    width: 100%;
}

.portfolio__navbar-links_logo {
    margin-right:  1rem;
}

.portfolio__navbar_brand_typewriter  {
    font-size:  1.8rem;
    color: var(--color-title);
    font-family: var(--font-family-2) !important;
    font-weight:  500;
    line-height: 25px;
    margin:  0 1rem;
    display:  inline-block;
    width: 19rem;
}



@keyframes typing {
    from { 
        width: 0% 
    }
    to { 
        width: 100% 
    }
}

@keyframes blink {
    from, to { 
        border-color: transparent 
    }
    50% { 
        border-color: orange; 
    }
}

.portfolio__navbar-links_container {
    display: flex;
    flex-direction: row;
    margin: 0rem 1rem 0rem 1rem;
    justify-content:  center;
    width:  55%;
}
.portfolio__navbar-links_container a {
    color: var(--color-title);
    font-size:  1rem;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.15em;
    display: inline-block;
    position: relative;
    padding: 1px 0 2px 0;

}

.portfolio__navbar-links_container  a:after {    
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background:  var(--color-title);
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }

  .portfolio__navbar-links_container a:hover:after { 
    width: 100%; 
    left: 0; 
  }


.portfolio__navbar-links_container a:active  {
    color: var(--color-text);
}

.portfolio__navbar-sign {
    display: flex;
    justify-content: flex-end;
    margin-left: 6rem;
    align-items: center;
}

.portfolio__navbar-links_container p,
.portfolio__navbar-sign p,
.portfolio__navbar-menu_container p {
    color: var(--color-bg);
    font-family: var(--font-family-2);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;

    margin: 0 2rem;
    cursor: pointer;
}

.portfolio__navbar-menu {
    margin-left: 1rem;
    color: var(--color-bg);
    display: none;
    position: relative;
}

.portfolio__navbar-menu svg {
    cursor: pointer;
}

.portfolio__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    
    text-align: end;
    background: var(--color-bg);
    padding: 0rem 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 1rem;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
}

.portfolio__navbar-menu_container p {
    margin: 1rem 0;
}

.portfolio__navbar-menu_container-links-sign {
    display: none;
}

@media screen and (max-width: 1050px) {
    .portfolio__navbar-links_container {
        display: none;
    }

    .portfolio__navbar-sign {
        display: none;
    }


    .portfolio__navbar-menu {
        display: flex;
    }

    .portfolio__navbar-menu_container-links-sign {
        display: block;
    }
}

@media screen and (max-width: 700px) {
    .portfolio__navbar {
        padding: 2rem 4rem;
    }

    .portfolio__navbar-menu_container{
        text-align: left;
    }
}

@media screen and (max-width: 550px) {
    .portfolio__navbar {
        padding: 2rem;
    }

    .portfolio__navbar-sign {
        display: none;
    }

    .portfolio__navbar-menu_container {
        top: 20px;
    }

    
}

.portfolio__social_logo {
    color: var(--color-title) !important; 
    justify-content: end;
    font-size: 1.5rem;
    margin: 0rem 1rem 0rem 1rem
}