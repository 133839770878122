.portafolio__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 119, 182, 0);
}

.portafolio__footer-links {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    margin-top: 2rem;
    flex-wrap: wrap;
    width: 100%;
    text-align: center;
}

.portafolio__footer-links div {
    width: 250px;
    margin: 1rem;
}

.portafolio__footer-links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}


.portafolio__footer-links_div p {
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: var(--font-family);
    color: var(--color-title);

    margin: 0.3rem 0;
    cursor: pointer;
}


.portafolio__footer-links_social {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}


.portafolio__footer-links_social p {
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: var(--font-family);
    color: var(--color-title);

    margin: 0.3rem 0;
    cursor: pointer;
}

.portafolio__footer-copyright {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
    width: 100%;
}

.portafolio__footer-copyright p {
    font-size: 0.8rem;
    font-family: var(--font-family);
    color: var(--color-title);
}

.portafolio_footer__social_logo {
    color: var(--color-title);
    margin: 0.3rem;
    font-size: 1.8rem;
}

@media screen and (max-width: 850px) {

    .portafolio__footer-links_social {
        flex-direction: row;
        justify-content: center;
    }

}

@media screen and (max-width: 558px) {

    .portafolio__footer-links div {
        margin: 1rem 0;
    }

    .portafolio_footer__social_logo {
        color: var(--color-title);
        margin: 0.3rem;
        font-size: 1.8rem;
    }

}
