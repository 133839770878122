.portfolio__aboutme_cursos {
    margin: 4rem;
}

.portfolio__aboutme_cursos li {
    text-align: left;
    width: 100%;
}

.lista_cursos {
    display: flex;
    flex-direction: row;
}

.lista_titulos {
    display: flex;
    flex-direction: row;
    line-height:  2rem;
    margin: 3rem 1rem 3rem 1rem;
    justify-content: space-between;
}

.lista_row {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.lista_head {
display: flex;
flex-direction: column;
align-items: flex-start;
margin-left: 2rem;
}

.lista_date {
color: var(--color-gray)
}

.lista_titulos_titulo {
    font-size: 1.3rem;
    font-weight: 700;
    justify-content: left;
}


.lista_titulos_titulo {
    font-size:  1.1rem;
}

.titulos__logos {
    width:  118px;
}

.lista_cursos_type {
    font-size:  0.9 rem;
    color: var(--color-subtext)

}

@media  (max-width: 850px) { 

    .lista_head {
        margin-left: 0rem;
        }

    .portfolio__aboutme_cursos {
        margin: 1rem 0rem;
    }
    
    .lista_titulos {
        margin: 3rem 0rem 3rem 0rem;
        flex-direction: column;
        align-items: center;
    }

    .lista_cursos_subtitle {
        margin: 0.5rem 1rem;
        align-self: center;
    }

    .lista_cursos_type {
        margin: 0rem 0rem;
        align-self: center;
    }

    .lista_date{
        align-self: center;
        margin-left: 1rem;
    }

}

@media  (max-width: 550px) { 

    .portfolio__aboutme_cursos {
        margin: 2rem 0rem;
    }
    
    .lista_titulos {
        margin: 3rem 0rem 3rem 0rem;
        align-items: center;

    }

}