@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@100;200;300;400;600&display=swap');


:root {
  --font-family: 'JetBrains Mono', sans-serif;
  
  --color-bg: #fff;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #031B34;
  --color-title: #0077B6;
  --color-subtext: #9b9b9b;
  --color-gray: #a4a4a4ff; 
}


