.portafolio__proyectos_main {
    margin: 6rem
}

.portafolio__proyectos_main img {
    max-width: 100%;
    align-self: center;

}


.portafolio__proyectos__code {
    margin-bottom: 2rem;
    margin-top: 2rem;
    width:  100%;
}


@media  (max-width: 850px) { 

.portafolio__proyectos_main {

    margin: 0rem 2rem;
}

}