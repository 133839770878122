.portfolio__home {
    display:  flex;
    flex-direction: column;
    align-items: center;
}

.portfolio__home_texto {
    padding:  auto;
    margin: 3rem 1rem 3rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.portfolio__home_name {
    font-size: 2rem;
    font-weight: 700;
    margin: 0.5rem 0rem 0.5em 0rem;
}
.portfolio__home_subtext {
    color: var(--color-text);
    line-height: 1.8rem;
    font-weight: 200;
}

.portfolio__home_intro {
    display: flex;
    margin: 2rem 1rem 2rem 1rem;
    height: 50%;
    justify-content: center;
    align-items: center;
}

.portfolio__home_fabs {
    height: 360px;
    margin: 1rem 2rem 1rem 0rem;
}

@media  (max-width: 850px) { 
    .portfolio__home_intro {
        flex-direction: column;
        margin: 1rem 0rem;

    }

    .portfolio__home_fabs {
        margin: 1rem;
        height: 320px;
    }
}