.portfolio__aboutme_education {
    margin: 2rem 1rem;
}
.portfolio__aboutme_education_title {
    margin:  3rem 1rem;
}

.titulos__logos_2 {
    width: 150px;
    margin: 1rem;
}

.vertical-timeline-element-content bounce-in {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.vertical-timeline-element-content p {
    margin:  0rem !important;
}


@media only screen and (min-width: 1170px) {

    .vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
        position: absolute;
        width: 100%;
        left: 105%;
        top: 6px;
        font-size: 1rem;
        margin-top: 0rem;
    }
}

@media only screen and (min-width: 1170px) {
    .vertical-timeline.vertical-timeline--two-columns {
        width: 100%;
        /*left: -20%; */
    }
}

.vertical-timeline-element-subtitle {
    font-weight:  300;
    margin:  1rem;
    font-size: 1rem;

}

.vertical-timeline-element-text {
    font-weight: 200 !important;
    font-size:  0.9rem !important;
    text-align: justify;
}

.vertical-timeline-element-content {
    position: relative;
    margin-left: 60px;
    background: #fff;
    border-radius:  0 !important; 
    padding: 1em;
    -webkit-box-shadow: 0 !important;
    box-shadow: 0 0 0 #ddd;
}

@media only screen and (min-width: 1170px) {
    .vertical-timeline--two-columns .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content .vertical-timeline-element-date, .vertical-timeline--two-columns .vertical-timeline-element:nth-child(2n):not(.vertical-timeline-element--left) .vertical-timeline-element-content .vertical-timeline-element-date {
        
        right: 130%;
        text-align: center;
    }
}


@media only screen and (max-width: 1169px) { 

    .vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
