.portafolio__proyectos {
    margin: 4rem 6rem; 
    line-height: 1.3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
}

.portafolio__proyectos__intro {
    width: 100%;
    text-align: justify;
    line-height: 1.8rem;
    font-weight: 200;
}

.portafolio__proyectos__intro h1 {
    margin: 1rem 4rem;
    font-size: 2rem;
}

.portafolio__proyectos__intro p {
    margin-bottom: 2rem;
    margin: 2rem 4rem;
    font-size: 1rem;
    line-height: 1.8rem;
    font-weight: 200;
}

.portafolio__proyectos_texto{
    margin:  2rem 0rem;
}

.portafolio__proyectos_texto h1, h2 {
 margin: 2rem 0rem;
}


@media  (max-width: 850px) {

    .portafolio__proyectos__intro {
        margin: 1rem 1rem; 
        text-align: center;
    }
    

    .portafolio__proyectos {
        margin: 0rem; 
        line-height: 1.3rem;
    }

    .portafolio__proyectos_texto{
        margin:  0.5rem 0rem;
    }
    
    .portafolio__proyectos_texto h1, h2 {
     margin: 0.5rem 0rem;
    }

}

@media  (max-width: 550px) {

    .portafolio__proyectos_texto{
        margin:  1rem 0rem;
    }
    
    .portafolio__proyectos_texto h1, h2 {
     margin: 1rem 0rem;
    }



}

.projects__card_left .portafolio_tags__tag {
font-size: 0.7rem !important;
margin: 0.5rem !important
}

.projects__card_left .portafolio_tags {
    justify-content: left !important;
    margin-left: 1rem; 
    width: 100%
}
