
.projects__card{
	display: flex;
	flex-direction: row;
	box-shadow: 0 15px 30px 1px rgba(128, 128, 128, 0.31);
	background:  white;
	border-radius: 2rem;
	margin: 3rem 1rem;
	height:  55vh;
	width: 80%;
}

/* 	Product details  */
.projects__card_left {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	width: 50%;
    color:  black;
}

/* 	Product Name */
.projects__card_left h1{
    margin: 1rem 2rem;
	font-size: 1.5rem !important;
    line-height: 2rem;
}

.projects__card_left_texto {
    margin:  0.5rem 2rem;
    font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 200;
	color: var( --color-subtext);
}

/* product image  */
.projects__card_img {
	transition: all 0.3s ease-out;
	display: inline-block;
	position: relative;
	overflow: hidden;
	width: 50%;
	display: inline-block;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
}

.projects__card_img img {
    vertical-align: middle;
	border-radius: inherit;
	object-fit: cover;
	width: 100%;
  	min-height: 100%;
}

/* Link al proyecto */
.card-btn {
	color: var( --color-title);
	font-size: 1.2rem;
	margin: 1rem 2rem;
  }
  
  
  .card-btn a {
	color: var( --color-title);
		background-image: linear-gradient(
		  to right,
		  #54b3d6,
		  #54b3d6 50%,
		  var( --color-title) 50%
		);
		background-size: 200% 100%;
		background-position: -100%;
		display: inline-block;
		padding: 5px 0;
		position: relative;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		transition: all 0.3s ease-in-out;
  }

  .card-btn a:before {
	content: '';
	background: #54b3d6;
	display: block;
	position: absolute;
	bottom: -3px;
	left: 0;
	width: 0;
	height: 3px;
	transition: all 0.3s ease-in-out;
  }
  
  .card-btn a:hover {
   background-position: 0;
  }
  
  .card-btn a:hover::before {
	width:100%;
  }

  /* 
  .card-btn:hover a {
	transform: scale(1.2);
}

*/

  
  @media  (max-width: 850px) {

	.projects__card{
		display: flex;
		flex-direction: column-reverse;
		margin: 3rem 1rem;
		height:  70vh;
		width: 100%;
	}

	.projects__card_left {
		flex-direction:  column;
		justify-content: space-around;
		height: 50%;
		width: 100%;
		padding: 1rem;

	}

	.projects__card_img {
		height: 50%;
		width: 100%;
		border-top-right-radius: 2rem;
		border-top-left-radius:  2rem;
		border-bottom-right-radius:  0;
	}

	.projects__card_img img {
		vertical-align: auto;
		object-fit:  cover !important;
	}

	.projects__card_left h1{
		margin: 0.5rem 0.5rem;
		font-size: 1.5rem;
		line-height: 1.8rem;
	}
	
	.projects__card_left_texto {
		margin:  0.5rem 1rem;
		font-size: 1rem;
	}

	.card-btn {
		color: var( --color-title);
		font-size: 0.8rem;
		margin: 1rem;
	  }
	
}

@media  (max-width: 550px) {

	.projects__card{
		display: flex;
		flex-direction: column-reverse;
		margin: 1rem auto;
		height:  100%;
		width: 100%;
	}

	.projects__card_left {
		flex-direction:  column;
		justify-content: space-around;
		height: 50%;
		width: 100%;
		margin:  0.5 rem;

	}

	.projects__card_img {
		height: 50%;
		width: 100%;
		border-top-right-radius: 2rem;
		border-top-left-radius:  2rem;
		border-bottom-right-radius:  0;
	}

	.projects__card_img img {
		vertical-align: auto;
		object-fit:  cover !important;
	}

	.projects__card_left h1{
		margin: 0.5rem 0.5rem;
		font-size: 1.1rem !important;
		line-height: 1.5rem !important;
	}
	
	.projects__card_left_texto {
		margin:  0.5rem 0rem;
		font-size: 0.8rem;
	}

	.card-btn {
		color: var( --color-title);
		font-size: 0.8rem;
		margin: 0.5rem;
	  }

}
