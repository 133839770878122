
.portafolio_data_viz {
    justify-content: left;
    margin: 4rem 6rem; 
}


.portafolio_data_viz__intro h1 {
    margin: 1rem 4rem;
    font-size: 2rem;
}

.portafolio_data_viz__intro p {
    margin-bottom: 2rem;
    margin: 2rem 4rem;
    font-size: 1rem;
    line-height: 1.8rem;
    font-weight: 200;
}

.portafolio_data_viz__intro {
    width: 100%;
}


.portafolio_data_viz__cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
}


@media  (max-width: 850px) { 

    .portafolio_data_viz {
        margin: 2rem 0rem
    }

.portafolio_data_viz__intro h1  {
    margin-left: 0rem;
}

.portafolio_data_viz__intro p {
    margin-left: 0rem;
}
 .portafolio_data_viz__intro {
    width: 100%;
 }

}