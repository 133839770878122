.emoji-hola {
    height: 18px;
}

.portoflio__aboutme {
    text-align: center;
    text-justify: inter-word;
}

.portfolio__aboutme_intro {
    margin: 3rem 10rem; 
    text-align: justify;
    font-weight: 100;
    line-height: 1.8rem;
}

@media  (max-width: 850px) { 

    .portfolio__aboutme_intro {
        margin: 3rem 1rem; 
        font-size: 1rem;
        line-height: 1.5rem;
        text-align: left;
    }

    .vertical-timeline-element-content  {
        text-align: left;
    }

    .vertical-timeline-element-subtitle{
        margin: 1rem 0rem
    }

}